import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import useLocalize from '../../../hooks/useLocalize';

import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';

const ClientPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityClientPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const clientPageProps = {
    id: data.sanityClientPage._id,
    metadata: { ...data.sanityClientPage._rawRoute, href },
    sections: data.sanityClientPage._rawSections,
    bubbleCtaSection: data.sanityClientPage._rawBubbleCtaSection,
  };
  // adding translatable text for sidebar inside of slantbanner
  clientPageProps.sections[0].sidebarText =
    data.sidebarText.edges[0].node.content;

  // add Product Category Entity to slant banner for fixed side bar
  clientPageProps.sections[0].productCategoryEntity =
    data.productCategoryEntity.edges;

  return (
    <Layout
      locale={locale}
      menuColor="white"
      sanityId={clientPageProps.id}
      {...clientPageProps.metadata}
    >
      {renderPageSections(clientPageProps.sections)}
      {clientPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...clientPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

ClientPage.propTypes = {
  data: PropTypes.shape({
    sanityClientPage: {},
    sidebarText: {},
    productCategoryEntity: {},
  }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

ClientPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: {
    locale: 'us',
  },
};

export default ClientPage;

export const query = graphql`
  query($slug: String) {
    sanityClientPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
    sidebarText: allSanitySidebarText {
      edges {
        node {
          content: _rawContent(resolveReferences: { maxDepth: 100 })
        }
      }
    }
    productCategoryEntity: allSanityProductCategoryEntity {
      edges {
        node {
          displayName: _rawDisplayName(resolveReferences: { maxDepth: 100 })
          shortDescription: _rawShortDescription(
            resolveReferences: { maxDepth: 100 }
          )
          productOrdering
          linkWithoutTitle {
            link {
              ... on SanityRoute {
                id
                routeName: _rawRouteName(resolveReferences: { maxDepth: 100 })
                slug {
                  current
                  _type
                }
              }
            }
          }
        }
      }
    }
  }
`;
